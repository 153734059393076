<template>
  <h3>
    Seiklusorienteerumine
  </h3>
  <div
    class="headerUnderline"
  />
  <p>
    Oma üritust alustame eelmisel aastatel juba populaarsust kogunud Seiklusorienteerumisega. Mida see täpsemalt tähendab? Ole pundis kas sõprade või iseendaga, leia üles kaardil etteantud punktid, vasta telefoni äpis olevatele küsimustele punktipõhiselt ning jõua esimesena tagasi finišisse. Antud orienteerumine on sobilik kõigile kergliikurite sõitjatele. Orienteerumise maksimaalne lubatud sõidukiirus – 25km/h
  </p>
  <h3>
    Off-Road võistlus
  </h3>
  <div
    class="headerUnderline"
  />
  <p>
    Korduvalt tõsiseid maastikusõidu entusiaste koondanud off-road võistlus on taaskord kohal. Seekordne rada tuleb grammike lühem ning teistsuguse ülesehitusega. Nimelt saab rada olema kiirem ja lühem, kuid sõitjatele korduvalt läbitav. Lisaks on seekordse Off-Roadi eesmärgiks anda pealtvaatajatele võimalus igat sõitjat oma sõidul jälgida ning talle ka julgustavalt kaasa elada. Soovituslik osaleda masinaga, mis saab maastikul probleemideta hakkama!
  </p>
  <h3>
    NIGHTRIDE
  </h3>
  <div
    class="headerUnderline"
  />
  <p>
    Iga ürituse must-have osa, ehk hetk mil nii metsasõitude, kui ka linna entusiastid ühendavad jõud ning näitavad üheskoos, kui palju on tegelikkuses kõiksugu elektriliste kergliikurite kasutajad ning austajaid. Sobilik kõigile kergliikurite sõitjatele. Orienteeruv raja pikkus: 12-15km
  </p>
  <h3>
    Pidu + Obstacle Track
  </h3>
  <div
    class="headerUnderline"
  />
  <p>
    Laupäevane Nightride lõppeb Voltride poe juures peoga, kuhu oleme üles seadnud ka Obstacle Tracki, millel saavad kõik huvilised oma oskused proovile panna. Track on läbitav nii aja peale, kui ka oma lõbuks! Voltride poes aga on kõigil osalejatel võimalus saada osa snäki- ning joogilauast.
  </p>
</template>

<script>
export default {
  name: "EventsDescriptions"
}
</script>

<style scoped lang="scss">

p {
  margin: 2rem;
  padding: 0; 

  font-family: Biryani;
  font-style: normal;
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: center;
  color: #FFDC22;
  margin-top: 2rem !important;
}

.headerUnderline {
  border-bottom: 2px solid #ffff;
  width: 4vw;
  margin: auto;
  align-self: center;
}

h3 {
  font-family: Biryani;
  font-style: normal;
  font-weight: 900;
  font-size: 2rem;
  line-height: 1rem;
  margin: 1rem;
  text-align: center;
  color: #FFDC22;
}

@media only screen and (min-width: 350px) {
  p {
    margin: 4rem 2rem;
  }
}

@media only screen and (min-width: 450px) {
  p {
    margin: 4rem;
  }
}
</style>
