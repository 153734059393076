<template>
  <div
    class="mainRoot"
  >
    <Header/>
    <Description/>
    <Timetable/>
    <EventsDescription/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Timetable from './components/TimeTable.vue'
import Description from './components/Description.vue'
import EventsDescription from './components/EventsDescriptions.vue'

export default {
  name: 'App',
  components: {
    Header,
    Timetable,
    Description,
    EventsDescription
  },
  methods: {
    onRegistration() {
      this.$refs.participants.refresh();
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Biryani:wght@200;300;400;600;700;800;900&display=swap');
@font-face {
  font-family: Road rage;
  src: url("./assets/road_rage.otf");
}

html {
  width: 100vw;
  margin: 0;
  padding: 0;

  font-size: 62.5%;

  overflow-x: hidden;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;

  background: #000000;
  font-family: Biryani;
}

.mainRoot {
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (min-width: 900px) and (min-height: 700px) {
  .mainRoot {
    max-width: 128rem;
    padding: 0 4rem; /* Padding needed for having padding and auto margin centering. */
    margin: 4rem auto;
    box-sizing: border-box;
  }
}
</style>
