<template>
  <div
    id="timetable"
  >
    <div
      id="innerTimetable"
    >
      <h3>
        Ajakava
      </h3>
      <div
        class="headerUnderline"
      />
      <template
        v-for="day in days"
        :key="day.header"
      >
        <EventDay
          :eventDay="day"
        />
      </template>
      <div
        class="facebookPromo"
      >
        <h2>
          Registeerimine ja muu info:
        </h2>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><a href="https://www.facebook.com/voltridecom"><linearGradient id="Ld6sqrtcxMyckEl6xeDdMa" x1="9.993" x2="40.615" y1="9.993" y2="40.615" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#2aa4f4"/><stop offset="1" stop-color="#007ad9"/></linearGradient><path fill="url(#Ld6sqrtcxMyckEl6xeDdMa)" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"/><path fill="#fff" d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"/></a></svg>
      </div>   
    </div>
  </div>
</template>

<script>
import EventDay from './EventDay.vue'

export default {
  name: 'Timetable',
  components: {
    EventDay
  },
  data () {
    return {
      days: [
        {
          header: "REEDE 13.08",
          times: [
            {
              time: "1900-2100",
              description: "Seiklusorienteeriumine",
              belowDescriptions: [
                "📍 Asukoht - Telliskivi 60"
              ]
            }
          ]
        },
        {
          header: "LAUPÄEV 14.08",
          times: [
            {
              time: "1100-1430",
              description: "Off-Road võistlus",
              belowDescriptions: [
                "📍 Asukoht - Kloostrimets"
              ]
            },
            {
              time: "2000-2200",
              description: "Õhtu ühissõit ehk NIGHTRIDE",
              belowDescriptions: [
                "📍 Asukoht - Telliskivi 60"
              ]
            },
            {
              time: "2200-8888",
              description: "Pidu Voltride Telliskivi 60 poes ning Obstacle Track poe kõrval",
              belowDescriptions: [
                "📍 Asukoht - Telliskivi 60"
              ]
            }
          ]
        }
      ]
    }
  }
}
</script>
<style scoped lang="scss">
#timetable {
  margin: 4rem 2rem 6rem;

  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  text-align: left;
}

.headerUnderline {
  border-bottom: 2px solid #ffff;
  width: 4vw;
  margin: auto;
  align-self: center;
  margin-bottom: 2rem;
}

.facebookPromo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

h3 {
  font-family: Biryani;
  font-style: normal;
  font-weight: 900;
  font-size: 2rem;
  line-height: 1rem;
  margin: 1rem;
  text-align: center;
  color: #FFDC22;
}

h2 {
  font-family: Biryani;
  font-style: normal;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 1rem;
  margin: 1rem;
  text-align: center;
  color: #FFDC22;
}

@media only screen and (min-width: 450px) {
  #timetable {
    margin: 4rem 4rem 6rem;
  }
}
</style>
