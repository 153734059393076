<template>
  <div 
    id="header"
  >
    <div
      class="container"
    >
      <img
        id="cover-photo"
        src="../assets/header_photo.png"
      />
    </div>
    <div
      class="container absolute"
    >
      <img
        id="voltride-logo"
        src="../assets/voltride_logo.png"
      />
      <p
        class="header-text title"
      >
        Electric Blast 21
      </p>
      <p
        class="header-text time"
      >
        13-14 august
      </p>
      <div class="arrow-container">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped lang="scss">
#header {
  position: relative;

  height: 100vh;
  width: 100%;
  margin-bottom: 2rem;

  overflow: hidden;
}

#header .container {
  height: 100%;
  width: 100%;
}

#cover-photo {
  height: 100%;
  width: 100%;

  object-fit: cover;
  object-position: 40% 50%;
}

.header-text {
  margin: 0;

  font-family: Road rage;
  font-style: normal;
  font-weight: normal;
  color: #FFDC22;
  text-shadow: 0px 4px 20px #000000;

  white-space: nowrap;
}

@mixin hintArrow($base, $pad) {
  .arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: auto;

    position: relative;
    bottom: $pad;
  }

  .chevron {
    position: absolute;
    width: $base * 3.5;
    height: $base * 0.8;
    opacity: 0;
    transform: scale(0.3);
    animation: move-chevron 3s ease-out infinite;
  }

  .chevron:first-child {
    animation: move-chevron 3s ease-out 1s infinite;
  }

  .chevron:nth-child(2) {
    animation: move-chevron 3s ease-out 2s infinite;
  }

  .chevron:before,
  .chevron:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    background: #FFDC22;
  }

  .chevron:before {
    left: 0;
    transform: skewY(30deg);
  }

  .chevron:after {
    right: 0;
    width: 50%;
    transform: skewY(-30deg);
  }

  @keyframes move-chevron {
  25% {
    opacity: 1;
    }
  33.3% {
    opacity: 1;
    transform: translateY($base * 3.8);
  }
  66.6% {
    opacity: 1;
    transform: translateY($base * 5.2);
  }
  100% {
    opacity: 0;
    transform: translateY($base * 8) scale(0.5);
  }
  }
};

@include hintArrow(
  $base: 0.6rem, 
  $pad: 6rem
);


@mixin headerContent($voltride_logo_height_base) {
  $header_text_margin_size: calc(#{$voltride_logo_height_base} * 0.1);
  $header_text_title_font_size: calc(#{$voltride_logo_height_base} * 0.5);
  $header_text_time_font_size: calc(#{$header_text_title_font_size} * 0.7);

  #header .container.absolute {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    box-sizing: border-box;

    padding-top: calc(50vh - (#{$voltride_logo_height_base} / 2) - 2rem);
  }

  #voltride-logo {
    height: $voltride_logo_height_base;
    width: auto;
  }

  .header-text.title {
    margin: $header_text_margin_size 0;

    font-size: #{$header_text_title_font_size};
    line-height: #{$header_text_title_font_size};
  }

  .header-text.time {
    font-size: #{$header_text_time_font_size};
    line-height: #{$header_text_time_font_size};
  }
};

@include headerContent(5rem);


@media only screen and (min-width: 350px) and (min-height: 350px) {
  @include hintArrow(
    $base: 1rem,
    $pad: 8.5rem
  );
  @include headerContent(7rem);

  #header {
    margin-bottom: 4rem;
  }
}

@media only screen and (min-width: 600px) and (min-height: 400px) {
  @include headerContent(8rem);
}

@media only screen and (min-width: 500px) and (min-height: 350px) {
  #cover-photo {
    transform: scale(1.2);
  }
}

@media only screen and (min-width: 900px) and (min-height: 700px) {

  @include headerContent(12rem);

  /* Override absolute layout. */
  #header .container.absolute {
    align-items: flex-end;
    justify-content: center;
    
    padding-top: 6rem;
  }

  #voltride-logo {
    padding-right: 6rem;
  }

  .header-text.title {
    padding-right: 2rem;
  }

  .header-text.time {
    padding-right: 31rem;
  }

  #header {
    height: auto;
  }

  #cover-photo {
    height: auto;

    transform: none;
  }

  .arrow-container {
    display: none;
  }
}
</style>
