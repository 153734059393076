<template>
  <p>
    <span class="elblast">Electric Blast</span> on esimene Eestis toimuv elektrilisi kergliikureid ühendav kahepäevane üritus, kus kohtuvad kõiksugu elektrilised sõidukid ning nende omanikud. Olgu nendeks siis tõukerattad, rulad, üksrattad, onewheelid või mis iganes elektri jõul liikuvad sõidukid. <span class="elblast">Electric Blast</span> on tulnud selleks, et ühendada neid kõiki!
    Kuna oleme Voltrides viimase kahe aasta jooksul korraldanud ligemale 25 üritust kergliikurite huvilistele, siis tundus loogilise sammuna võtta ette üks grandioosne ning mitmepäevane üritus, ehk isegi midagi festivali laadset? 
</p>
</template>

<script>
export default {
  name: "Description"
}
</script>

<style scoped lang="scss">
p {
  margin: 2rem;
  padding: 0; 

  font-family: Biryani;
  font-style: normal;
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: center;
  color: #FFDC22;
}

.elblast {
  font-family: Road rage !important;
  font-weight: 200 !important;
}

@media only screen and (min-width: 350px) {
  p {
    margin: 4rem 2rem;
  }
}

@media only screen and (min-width: 450px) {
  p {
    margin: 4rem;
  }
}
</style>
