<template>
  <div class="root">
    <p
      class="header"
      @click="open_close"
    >
      {{eventDay.header}} 
      <img
        v-if="!isOpen"
        src="../assets/arrow.png"
        class="arrow"
      />
      <img
        v-else
        src="../assets/arrow.png"
        class="arrow up"
      />
    </p>
    <TransitionCollapseHeight>
      <div
        v-if="isOpen"
        class="times"
      >
        <template
          v-for="time in eventDay.times"
          :key="time.time"
        >
          <div
            class="time_root"
          >
            <div
              class="row"
            >
              <p
                class="time"
              >
                {{ time.time }}
              </p>
              <p
                class="time_description"
              >
                {{ time.description }}
              </p>
              
            </div>
            <div
                v-if="time.belowDescriptions != null"
                class="below_descriptions"
              >
                <template
                  v-for="desc in time.belowDescriptions"
                  :key="desc"
                >
                  <p
                    class="below_description"
                  >
                    {{ desc }}
                  </p>
                </template>
              </div>
          </div>
        </template>
      </div>
    </TransitionCollapseHeight>
  </div>
</template>

<script>
import TransitionCollapseHeight from './TransitionCollapseHeight.vue'

export default {
  name: "EventDay",
  props: ['eventDay'],
  components: {
    TransitionCollapseHeight
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    open_close() {
      this.isOpen = !this.isOpen;
    }
  }
}
</script>

<style scoped lang="scss">
.root {
  margin-bottom: 2rem;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.root:last-of-type {
  margin-bottom: 0;
}

.header {
  display: flex;
  width: 100%;
  margin: 0;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  font-family: Road Rage;
  font-style: normal;
  font-weight: normal;
  font-size: calc(5rem * 0.5 * 0.7);
  line-height: calc(5rem * 0.5 * 0.7 * 1.3);
  color: #FFDC22;
  cursor: pointer;
}

.arrow {
  max-height: 1.6rem;
  width: auto;
  padding: 0 2rem;
}

.up {
  max-height: 1.6rem;
  width: auto;
  padding-left: 2rem;
  transform: rotate(-180deg);
}

.times {
  font-family: Biryani;
  font-style: normal;
  font-weight: 900;
  font-size: calc(5rem * 0.5 * 0.7 * 0.5);
  color: #FFFFFF;
  align-self: center;
  width: 100%;
}

.row {
  margin: 0;
  display: flex;
  flex-direction: row;
}

.time_root {
  display: flex;
  flex-direction: column;
}

.time_description {
  margin: 1vw 1vw 0vw 1vw;
  font-weight: 400 !important;
}

.time {
  margin: 1vw 1vw 0vw 1vw;
  white-space: nowrap;
}

.below_descriptions {
  margin-left: 6vw;
  font-weight: 400 !important;
}

.below_description {
  margin: 1vw;
}

.enter-active,
.leave-active {
  overflow: hidden;
  transition: height .5s linear;
}

@media only screen and (min-width: 350px) and (min-height: 350px) {
  .header {
    font-size: calc(7rem * 0.5 * 0.7);
    line-height: calc(7rem * 0.5 * 0.7 * 1.3);
  }
  .times {
    font-size: calc(7rem * 0.5 * 0.7 * 0.5);
  }
}

@media only screen and (min-width: 600px) and (min-height: 400px) {
  .header {
    font-size: calc(8rem * 0.5 * 0.7);
    line-height: calc(8rem * 0.5 * 0.7 * 1.3);
  }
  .times {
    font-size: calc(8rem * 0.5 * 0.7 * 0.5);
  }
}

@media only screen and (min-width: 900px) and (min-height: 700px) {
  .header {
    font-size: calc(10rem * 0.5 * 0.7);
    line-height: calc(10rem * 0.5 * 0.7 * 1.3);
  }
  .times {
    font-size: calc(10rem * 0.5 * 0.7 * 0.5);
  }
}
</style>
